import React from "react"

import SEO from "@components/SEO/seo"
import { StaticNavbarLayout } from "@components/layouts/static-navbar-layout"

import { SignOut } from "@views/SignOut"

export default () => (
  <StaticNavbarLayout>
    <SEO title="Sign Out" keywords={["Sign Out"]} />
    <SignOut />
  </StaticNavbarLayout>
)
